import Script from "next/script";
import React, {useState} from "react";

interface Props {
    googleAnalyticsId: string;    
}

const GoogleAnalytics = ({
                              googleAnalyticsId
}: Props) => {
    console.info("GoogleTag");
    const [isProdEnv] = useState(true);    
    if (!googleAnalyticsId && !isProdEnv) {
        console.warn("No googleAnalytics ID provided");
        return null
    }
    //console.debug("googleAnalytics ID Found " + googleAnalyticsId);
    return (
        <>
            <Script
                src={`https://www.googletagmanager.com/gtag/js?id=${googleAnalyticsId}`}
                strategy="afterInteractive"
            />
            <Script
                id="gtag-init"
                strategy="afterInteractive"
                dangerouslySetInnerHTML={{
                    __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${googleAnalyticsId}');
          `,
                }}
            />
        </>
    );
};

export default GoogleAnalytics